import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Parallax } from "react-parallax";
import { Helmet } from "react-helmet-async";

function HowItWorks() {
  const [isOpen, setIsOpen] = useState(false);
  const videoId = "0lN9Z0Gea98";
  return (
    <>
      <Helmet>
        <meta
          name="og:title"
          content="How It Works "
        />
        <meta
          name="description"
          content="Explore how our app works. Watch the demo video and see the amazing features in action."
        />
        {/* Add more meta tags as needed for SEO optimization */}
      </Helmet>
      <section id="how-it-works" className="hero-wrap">
        <div className="hero-mask opacity-7 bg-dark" />

        <Parallax
  bgImage="images/howit.jpg"
  bgImageAlt="Intro"
  strength={200}
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500px', // Set a fixed height for better centering
  }}
>
          <div className="hero-content section">
            <div className="container text-center">
              <h2 className="text-9 fw-600 text-white mb-4 mb-lg-5">
                How it's Works? Play And Watch!
              </h2>
              <span
                onClick={() => {
                  setIsOpen(true);
                }}
                className="popup-youtube btn-video-play"
              >
                <i className="fas fa-play" />
              </span>
              <p className="text-white font text-uppercase ls-1 mt-2 mb-0">
                View Promo
              </p>
            </div>
          </div>
        </Parallax>
        <ModalVideo
          channel={"youtube"}
          autoplay
          isOpen={isOpen}
          videoId={videoId}
          onClose={() => setIsOpen(false)}
        ></ModalVideo>
      </section>
    </>
  );
}

export default HowItWorks;
