import React from "react";
import { Helmet } from "react-helmet-async";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";
import { useNavigate } from "react-router-dom";

function Pricing() {
  const navigate = useNavigate();

  const handleClick = (amount, desc) => {
    // Perform any actions before navigating to the checkout route.
    console.log(`Amount: ${amount} euros`);

    // Navigate to the checkout route with state
    navigate("/checkout", { state: { price: amount , description: desc } });
  };
  return (
    <section
      id="pricing"
      className={
        "section bg-light " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
          <Helmet>
        <meta
          name="description"
          content="Explore IPTVZone's pricing plans and choose the best subscription for your streaming experience. Unmatched value with free trial access for 12 hours, monthly explorer Є9.99, annual odyssey Є39.99, and half-year voyage plans Є29.99."
        />
        <meta
          property="og:title"
          content="IPTVZone Pricing - Choose the Best Plan for Your Streaming Experience"
        />
        <meta
          property="og:description"
          content="Explore IPTVZone's pricing plans and choose the best subscription for your streaming experience. Unmatched value with trial access, monthly explorer, annual odyssey, and half-year voyage plans."
        />
        <meta property="og:image" content="URL_TO_YOUR_LOGO_IMAGE" />
        <meta property="og:url" content="URL_TO_YOUR_WEBSITE" />
      </Helmet>
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Pricing Plans</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-5">
          Unmatched Value for Your Ultimate Streaming Experience.
        </p>
        <div className="row gy-4">
          <div className="col-sm-6 col-lg-3">
            <div className="card text-center border-0 shadow-md">
              <div className="card-header text-6 fw-500 text-bg-secondary border-bottom-0 py-4">
                Trial Access
              </div>
              <div className="card-body">
                <h5 className="text-9 fw-600 mb-0">Free</h5>
                <p className=" text-2 badge text-bg-warning fw-600 rounded-pill">
                  12 hours
                </p>
                <ul className="list-style-2 text-2 text-start">
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    14,000+ Channels
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    100,000+ VOD Library
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    All device supported
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-times" />
                    </span>
                    High quality resolution 4K & UHD
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-times" />
                    </span>
                    Anti-Freeze technology
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-times" />
                    </span>
                    Technical support
                  </li>
                </ul>
                <a href="https://api.whatsapp.com/send?phone=441206700663&text=Hi, I would like to receive the free 12h trial please😀" target="_blank" className="btn btn-secondary text-uppercase my-1">
                  Start Free Trial
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card text-center border-0 shadow-md">
              <div className="card-header text-6 fw-500 text-bg-secondary border-bottom-0 py-4">
                Monthly Explorer
              </div>
              <div className="card-body">
                <h5 className="text-9 fw-600 mb-0">€9.99</h5>
                <p className=" text-2 badge text-bg-warning fw-600 rounded-pill">
                  1 Month
                </p>
                <ul className="list-style-2 text-2 text-start">
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    High quality resolution 4K & UHD
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    14,000+ Channels
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    100,000+ VOD Library
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    All device supported
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    Anti-Freeze technology
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    Technical Support
                  </li>
                </ul>
                <button
                  onClick={() => {
                    handleClick(9.99, "One month subscription");
                  }}
                  className="btn btn-secondary text-uppercase my-1"
                >
                  Subscribe Monthly{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card text-center border-0 shadow-md">
              <div className="card-header text-6 fw-500 text-bg-primary border-bottom-0 py-4">
                Annual Odyssey
              </div>
              <div className="card-body">
                <h5 className="text-9 fw-600 mb-0">€37.99</h5>
                <p className=" text-2 badge text-bg-warning fw-600 rounded-pill">
                  12 Months
                </p>
                <ul className="list-style-2 text-2 text-start">
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    High quality resolution 4K & UHD
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    14,000+ Channels
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    100,000+ VOD Library
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    All device supported
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    Anti-Freeze technology
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    Technical Support
                  </li>
                </ul>
                <button
                  onClick={() => {
                    handleClick(37.99, "One year subscription");
                  }}
                  className="btn btn-primary text-uppercase my-1"
                >
                  Subscribe Annually{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="card text-center border-0 shadow-md">
              <div className="card-header text-6 fw-500 text-bg-secondary border-bottom-0 py-4">
                Half-Year Voyage
              </div>
              <div className="card-body">
                <h5 className="text-9 fw-600 mb-0">€29.99</h5>
                <p className=" text-2 badge text-bg-warning fw-600 rounded-pill">
                  6 Months
                </p>
                <ul className="list-style-2 text-2 text-start">
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    High quality resolution 4K & UHD
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    14,000+ Channels
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    100,000+ VOD Library
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    All device supported
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    Anti-Freeze technology
                  </li>
                  <li>
                    <span className="text-1 me-2">
                      <i className="fas fa-check" />
                    </span>
                    Technical Support
                  </li>
                </ul>
                <button
                  onClick={() => {
                    handleClick(29.99, "Half a year subscription");
                  }}
                  className="btn btn-secondary text-uppercase my-1"
                >
                  Get 6-Month Plan{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
