import { createContext, useContext, useState } from 'react';

const PriceContext = createContext();

export const PriceProvider = ({ children }) => {
  const [planPrice, setPlanPrice] = useState(0);

  return (
    <PriceContext.Provider value={{ planPrice, setPlanPrice }}>
      {children}
    </PriceContext.Provider>
  );
};

export const usePrice = () => {
  return useContext(PriceContext);
};