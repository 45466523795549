import React from "react";
import { Helmet } from "react-helmet-async";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

function Features() {
  return (
    <section
      id="features"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
       <Helmet>
        <meta
          name="description"
          content="Discover the features of IPTVZone. Tailor your entertainment with effortless streaming, versatile streaming hub, low pricing, fully secured experience, easy-to-use interface, and receive constant updates for a fresh streaming experience."
        />
        <meta
          property="og:title"
          content="IPTVZone Features - Tailor Your Entertainment, Effortless Streaming"
        />
        <meta
          property="og:description"
          content="Discover the features of IPTVZone. Tailor your entertainment with effortless streaming, versatile streaming hub, low pricing, fully secured experience, easy-to-use interface, and receive constant updates for a fresh streaming experience."
        />
        <meta property="og:image" content="URL_TO_YOUR_LOGO_IMAGE" />
        <meta property="og:url" content="URL_TO_YOUR_WEBSITE" />
      </Helmet>

      <div className="container">
        <h2 className="text-9 fw-600 text-center">IPTVZone Features</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-5">
          Tailor Your Entertainment, Effortless Streaming.
        </p>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-pencil-ruler" />
                  </div>
                  <h3>Edit Channel Lineup</h3>
                  <p>
                    Tailor your viewing choices effortlessly, ensuring a
                    personalized and enjoyable streaming selection from a
                    diverse array of options.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-cloud-upload-alt" />
                  </div>
                  <h3>Versatile Streaming Hub</h3>
                  <p>
                    At IPTVZone, experience entertainment anywhere, anytime – on
                    your TV, cellphone, or PC.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-tags" />
                  </div>
                  <h3>Low Pricing</h3>
                  <p>
                    At IPTVZone, we're breaking the market with unbeatable
                    prices for premium streaming. Why settle? Enjoy the best at
                    a fraction of the cost!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center mb-4 mb-xl-0">
            <img alt="" src="images/app-showcase1.png" className="img-fluid" />
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-lock" />
                  </div>
                  <h3>Fully Secured</h3>
                  <p>
                    At IPTVZone, your entertainment is our priority. Enjoy fully
                    secured streaming, ensuring a worry-free and immersive
                    experience.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-paper-plane" />
                  </div>
                  <h3>Easy to Use</h3>
                  <p>
                    At IPTVZone, it's as easy as entering your username,
                    password, and the provided link into your player – unlocking
                    seamless entertainment effortlessly.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-sync-alt" />
                  </div>
                  <h3>FreshStream Updatess</h3>
                  <p>
                    At IPTVZone, enjoy a constantly refreshed lineup, ensuring
                    you're always up-to-date with the latest movies, series, and
                    channels.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
