import React from "react";
import { Parallax } from "react-parallax";
import { Helmet } from "react-helmet-async";

function Counter() {
  return (
    <section id="counter" className="hero-wrap">
      <Helmet>
        <meta
          name="description"
          content="Explore IPTVZone's achievements - 100% secured, 1.2K happy users, 1K positive ratings, and 940 annual odysseys. Join us for a secure and delightful streaming experience."
        />
        <meta
          property="og:title"
          content="IPTVZone Counter - Secure, Happy Users, Positive Ratings"
        />
        <meta
          property="og:description"
          content="Explore IPTVZone's achievements - 100% secured, 1.2K happy users, 1K positive ratings, and 940 annual odysseys. Join us for a secure and delightful streaming experience."
        />
      </Helmet>

      <div className="hero-mask opacity-8 bg-dark" />

      <Parallax bgImage="images/checkout.jpg" bgImageAlt="Intro" strength={200}>
        <div className="hero-content section">
          <div className="container">
            <div className="row gy-5 justify-center">
              <div className="col-6 col-md-3">
                <div className="featured-box text-center">
                  <div className="featured-box-icon text-white">
                    <i className="fas fa-lock" />
                  </div>
                  <h4 className="text-11 text-white fw-500">100%</h4>
                  <p className="text-light mb-0">Secured</p>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="featured-box text-center">
                  <div className="featured-box-icon text-white">
                    <i className="fas fa-users" />
                  </div>
                  <h4 className="text-11 text-white fw-500">1.4K</h4>
                  <p className="text-light mb-0">Happy Users</p>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="featured-box text-center">
                  <div className="featured-box-icon text-white">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                  <h4 className="text-11 text-white fw-500">1.1K</h4>
                  <p className="text-light mb-0">Positive Ratings</p>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="featured-box text-center">
                  <div className="featured-box-icon text-white">
                    <i className="fas fa-gem" />
                  </div>
                  <h4 className="text-11 text-white fw-500">1117</h4>
                  <p className="text-light mb-0">Annual Odyssey's</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  );
}

export default Counter;
