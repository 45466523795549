import React from "react";
import { Helmet } from "react-helmet-async";
import {
  HeaderConfig,
  IntroContentConfig,
  IntroThemeConfig,
  appliedConfig,
} from "../config/commonConfig";

const AboutUs = () => {
  return (
    <section
      id="about"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
          appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <Helmet>
        <meta
          name="description"
          content="Discover the ultimate entertainment experience with IPTVZone. Enjoy premium content at affordable prices from any device, anywhere in the world."
        />
        <meta
          property="og:title"
          content="About IPTVZone - Premium Entertainment Experience"
        />
        <meta
          property="og:description"
          content="Discover the ultimate entertainment experience with IPTVZone. Enjoy premium content at affordable prices from any device, anywhere in the world."
        />
        <meta property="og:image" content="URL_TO_YOUR_LOGO_IMAGE" />
        <meta property="og:url" content="URL_TO_YOUR_WEBSITE" />
      </Helmet>

      <div
        className={
          "container " +
          (appliedConfig.appliedIntro === IntroThemeConfig.Intro1 &&
            appliedConfig.appliedIntroContent === IntroContentConfig.Image
            ? "pt-5 "
            : " ")
        }
      >
        <h2 className="text-9 fw-600 text-center">About IPTVZone</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4"></p>
        <div className="row">
          <div className="col-lg-6 text-center flex align-center">
            {" "}
            <img
              className="img-fluid uk-img three-img"
              src="images/new3.png"
              alt="Laptop"
            />{" "}
          </div>
          <div className="col-lg-6 text-center text-lg-start">
            <h2 className="text-6 mb-3 mt-4">
              Unlock Endless Entertainment with IPTVZone
            </h2>
            <p>
              Welcome to IPTVZone, where we bring you a seamless and inspired
              design for your ultimate entertainment experience. Our app is
              thoughtfully crafted to redefine how you enjoy content, making it
              all effortlessly easy.
            </p>
            <p>
              At IPTVZone, we offer premium content at affordable prices. Enjoy
              universal access to your favorite shows and movies from any
              device, anywhere in the world. Elevate your entertainment
              experience with us!
            </p>
         
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
