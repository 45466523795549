import React from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet-async";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

function AppScreenshots() {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );
  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 400,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section
      id="screenshot"
      className={
        "section bg-light " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
          appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <Helmet>
        <meta
          name="description"
          content="Explore the screenshots of IPTVZone, offering a wide range of premium channels and endless entertainment. Discover affordable access to diverse content."
        />
        <meta
          property="og:title"
          content="IPTVZone Screenshots - Premium Channels and Entertainment"
        />
        <meta
          property="og:description"
          content="Explore the screenshots of IPTVZone, offering a wide range of premium channels and endless entertainment. Discover affordable access to diverse content. Among the content: HBO, Netflix, Disney+, Discovery, History channels, NBC, Sky, Sport channels etc.."
        />
        <meta property="og:image" content="URL_TO_YOUR_LOGO_IMAGE" />
        <meta property="og:url" content="URL_TO_YOUR_WEBSITE" />
      </Helmet>

      <div className="container">
        <h2 className="text-9 fw-600 text-center">What IPTVZone offers</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4">
          Discover affordable access to a world of diverse channels and endless
          entertainment.
        </p>
        <Slider {...settings}>
        <div className="item img">
            <img className="img-fluid content-img" alt="netflix" src="images/netflix.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="bbc" src="images/bbc.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="sky" src="images/sky.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="disney+" src="images/disney.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="appleTV" src="images/appletv.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="National geographic" src="images/national.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="hbo" src="images/hbo.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="sport5" src="images/sport5.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="MTV" src="images/mtv.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="Discovery" src="images/discovery.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="NBC" src="images/nbc.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="Premier league" src="images/premier.png" />
          </div>
          <div className="item img">
            <img className="img-fluid content-img" alt="History channel" src="images/history.png" />
          </div>
        </Slider>
        <div className="lead text-center mt-4 mb-4">
        Lost in choices? 🤔 {" "}
          <a
            href="https://api.whatsapp.com/send?phone=441206700663&text=Hi, I would like to know..." target="_blank"
          >
            Ask us on WhatsApp! 🚀
          </a>
        </div>
      </div>
    </section>
  );
}

export default AppScreenshots;
