import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export const PaypalCheckoutButton = (props) => {
  const { state } = useLocation();
  const { price } = state || {};
  const { description } = state || {};

  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  console.log(price, description, props.userData);

  const handleApprove = (orderId) => {
    setPaidFor(true);
  };

  const sendEmail = async (props) => {
    try {
      const res = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        props.userData
      );
      console.log(props.userData);
    } catch (err) {
      console.log(err);
    }
  };

  if (paidFor) {
    swal(
      "Welcome to IPTVZone! ",
      "Your subscription is active. Your IPTV details are on their way to your email. Please make sure to check in your spam as well. Get ready for non-stop entertainment!",
      "success"
    );
    sendEmail(props);
    navigate("/");
  }

  if (error) {
    swal(error, "", "error");
  }

  return (
    <div>
      <Helmet>
        <title></title>
        <meta name="og:title" content="PayPal Checkout - IPTVZone" />
        <meta
          name="description"
          content={
            "Complete the PayPal checkout, total amount that needs to be paid:" +
            price +
            "euros. process for your subscription purchase. Confirm your subscription and get ready for non-stop entertainment!"
          }
        />
        {/* Add more meta tags as needed for SEO optimization */}
      </Helmet>

      <PayPalScriptProvider
        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID , "currency":"EUR" }}
      >
        <PayPalButtons
          onClick={(data, actions) => {
            const hasAlreadyBaughtSubscription = false;
            if (hasAlreadyBaughtSubscription) {
              setError("You already purchased this subscription");
              return actions.reject();
            } else {
              return actions.resolve();
            }
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: description,
                  amount: {
                    value: price,
                  },
                },
              ],
            });
          }}
          onApprove={async (data, action) => {
            const order = await action.order.capture();
            console.log("order", order);

            handleApprove(data.orderID);
          }}
          onCancel={() => {}}
          onError={(err) => {
            setError(err);
            console.log("PayPal checkout onError", err);
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};
