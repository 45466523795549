import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { usePrice, PriceProvider } from "./config/priceContext";
import {
  HeaderConfig,
  IntroThemeConfig,
  appliedConfig,
} from "./config/commonConfig";
import { Tooltip } from "./components/Tooltip";
import { Main } from "./pages/main";
import { Checkout } from "./pages/checkout";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ClassicHeader from "./components/themes/Headers/ClassicHeader";
import DefaultHeader from "./components/themes/Headers/DefaultHeader";
import OverlayMenu from "./components/themes/Headers/OverlayMenu";
import SideHeader from "./components/themes/Headers/SideHeader";
import Footer from "./components/Footer";
import "./App.scss";

function App() {
  const { setPlanPrice } = usePrice();
  const appliedHeader = appliedConfig.appliedHeader;

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const getHeader = () => {
    if (appliedHeader === HeaderConfig.ClassicHeader) {
      return <ClassicHeader></ClassicHeader>;
    } else if (appliedHeader === HeaderConfig.DefaultHeader) {
      return <DefaultHeader></DefaultHeader>;
    } else if (
      appliedHeader === HeaderConfig.SideHeaderDark ||
      appliedHeader === HeaderConfig.SideHeaderLight
    ) {
      return (
        <SideHeader
          darkTheme={appliedHeader === HeaderConfig.SideHeaderDark}
        ></SideHeader>
      );
    } else if (appliedHeader === HeaderConfig.OverlayMenu) {
      return <OverlayMenu></OverlayMenu>;
    } else {
      return <DefaultHeader></DefaultHeader>;
    }
  };

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <PriceProvider>
      <HelmetProvider>
        <div
          className={
            appliedHeader === HeaderConfig.SideHeaderDark ||
            appliedHeader === HeaderConfig.SideHeaderLight
              ? "side-header"
              : ""
          }
          style={{ position: "relative" }}
        >
          <Helmet>
            <title>IPTVZone</title>
            <meta
              name="description"
              content="Cheapest IPTV Subscritpion! Guaranteed!!"
            />
            {"IPTV Cheap Subscritpion Worldwide Secured"}
          </Helmet>

          <div id="main-wrapper">
            {getHeader()}

            <Router>
              <Routes>
                <Route
                  path="/"
                  element={<Main setPlanPrice={setPlanPrice}></Main>}
                ></Route>
                <Route path="/checkout" element={<Checkout></Checkout>}></Route>
              </Routes>
            </Router>
            <Footer handleNavClick={handleNavClick}></Footer>
          </div>

          {/* back to top */}
          <Tooltip text="Back to Top" placement="left">
            <span
              id="back-to-top"
              className="rounded-circle"
              style={{ display: scrollTopVisible ? "inline" : "none" }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <i className="fa fa-chevron-up"></i>
            </span>
          </Tooltip>
          <ToastContainer />
          <TermsAndConditions></TermsAndConditions>
          <PrivacyPolicy></PrivacyPolicy>
        </div>
      </HelmetProvider>
    </PriceProvider>
  );
}

export default App;
