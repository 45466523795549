import React from "react";

function PrivacyPolicy() {
  return (
    <div id="privacy" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Privacy Policy</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p className="lead">
              Thank you for choosing IPTVZone. This Privacy Policy outlines how
              we collect, use, and protect your personal information when you
              use our services.
            </p>
            <br />
            <ul className="lh-lg">
                <h3>1. Information We Collect</h3>
              <li>
                1.1. Personal Information: When you create an account, we may
                collect personal information such as your name, email address,
                and payment details.
              </li>
              <li>
                1.2. Usage Information: We may collect information about your
                usage of our services, including IP addresses, device
                information, and browsing history.
              </li>
              <br />
                <h3>2. How We Use Your Information</h3>
              <li>
                2.1. We use the collected information to provide and improve our
                services, personalize your experience, process transactions, and
                send periodic communications.
              </li>
              <li>
                2.2. Your email address may be used for service-related
                notifications, updates, and promotional materials. You can opt
                out of promotional emails.
              </li>
              <br />
                <h3>3. Data Security</h3>
              <li>
                3.1. We implement security measures to protect your personal
                information. However, no method of transmission over the
                internet or electronic storage is entirely secure.
              </li>
              <br />
                <h3>4. Sharing Your Information</h3>
              <li>
                4.1. We do not sell, trade, or otherwise transfer your personal
                information to third parties without your consent, except as
                described in this policy.
              </li>
              <li>
                4.2. We may share your information with trusted third parties
                who assist us in operating our website, conducting our business,
                or servicing you.
              </li>
              <br />
                <h3>5. Your Rights</h3>
              <li>
                5.1. You have the right to access, correct, or delete your
                personal information. Contact us at iptvzoneltd@gmail.com to exercise
                these rights.
              </li>
              <li>
                4.2. We may share your information with trusted third parties
                who assist us in operating our website, conducting our business,
                or servicing you.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
