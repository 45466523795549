import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet-async";


const FAQs = () => {
  const faqsData = [
    {
      question: "What is IPTV?",
      answer:
        "IPTV delivers television content over the internet, allowing viewers to access shows on various devices with interactive features. It's a modern alternative to traditional broadcasting methods.        ",
    },
    {
      question: "What payment methods are available?",
      answer:
        "You can pay for your subscription with PayPal / International Credit Cards. Payments on our site are 100% secure. ",
    },
    {
      question: "Which devices can be used for IPTV?",
      answer:
        "All devices are supported: smart TV (Samsung, Sony, LG…), all Android devices (phones and Tablets), Apple TV, iPhone, Google Chromecast, MAG box in the STB emulator app, and FireStick.",
    },
    {
      question: "What apps are recommended to use the IPTV subscription?",
      answer:
        "Our most recommended app is Televizo, you can download it in your streamer and for Android. If you don't have a streamer or your'e using an iPhone we recommend the IPTV smarters pro/lite app.",
    },
    {
      question: "What content is available on IPTVZone?",
      answer:
        "IPTV offers an extensive VOD library boasting over 100,000 movies and series, including popular platforms like Netflix, Disney+, and HBO. Our service also provides a global channel selection with 14,000+ channels from across the world, supporting 4K and UHD quality for an immersive viewing experience.",
    },
    {
      question: "How many connections at once?",
      answer:
        "You can install your account on multiple devices, you should be aware that it only functions on one at a time.",
    },
    {
      question: "Are there any geographic restrictions?",
      answer:
        "Yes, The subscription is supported at: UK USA Canada Greece Israël Ireland Germany Portugal Belgium France Netherlands Switzerland Austria Poland Spain Malta Italy Australia New Zealand Arabic India Pakistan Iran Turkey Azerbaycan Kurdistan Africa Afghanistan Armenia Exyu Slovenia Albania Latino Brazil Caribbean Suriname Sweden Denmark Norway Finland Czech Bulgaria Romania Hungary Russia Georgia/Kazakh Ukraine Lithuania Latvia Estonia China/HK Malaysia Philippines Indonesia Korea Thailand Taiwan Vietnam and others",
    },
    {
      question: "Is IPTVZone compatible with my internet speed?",
      answer:
        "The ideal internet speed for streaming on IPTV is at least 25-35 Mbps.",
    },
  ];

  return (
    <>
     <Helmet>
        <meta
          name="og:title"
          content="Frequently Asked Questions - Your App Name"
        />
        <meta
          name="description"
          content="Find answers to commonly asked questions about IPTVZone. Learn about payment methods, supported devices, recommended apps, available content, and more."
        />
      </Helmet>
    <section id="faq" className="section">
      <div className="container">
        <h2 className="text-9 fw-600 text-center">
          Frequently Asked Questions
        </h2>
        <hr className="heading-separator-line bg-dark opacity-10 mx-auto" />
        <p className="lead text-center mb-4">
          You can present your app FAQ here!
        </p>
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto">
            <hr className="mb-0 opacity-1" />
            <Accordion flush defaultActiveKey="0" className="arrow-end">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
            <hr className="mt-0 opacity-1" />
          </div>
        </div>
        <div className="lead text-center mt-4">
          Can't find it here?{" "}
          <a
            href="#contact"
            className="smooth-scroll link-offset-2 link-underline link-underline-opacity-0-hover"
          >
            Tell us your query here.
          </a>
        </div>
      </div>
    </section>
    </>
  );
};

export default FAQs;
