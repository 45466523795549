import React from "react";

function TermsAndConditions() {
  return (
    <div id="terms" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Terms of Use</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <h3 className="mb-3 mt-4">1. Acceptance of Terms</h3>
            <p>
              By accessing and using the services provided by IPTVZone, you
              agree to be bound by these Terms of Use. If you do not agree to
              these terms, please refrain from using our services.
            </p>
            <h3 className="text-4">2. User Accounts</h3>
            <p>
              2.1. Users must be at least 18 years old to create an account.
              <br />
              2.2. Users are responsible for maintaining the confidentiality of
              their account information and are liable for any activities
              conducted through their account.
            </p>
            <h3 className="text-4">3. Service Access</h3>
            <p>
              3.1. IPTVZone grants users a limited, non-exclusive,
              non-transferable, and revocable license to access and use our
              services.
              <br />
              3.2. Users may not reproduce, duplicate, copy, sell, resell, or
              exploit any portion of the services without explicit written
              permission.
            </p>
            <h3 className="text-4">4. Content</h3>
            <p>
              4.1. Users are responsible for the content accessed or transmitted
              through IPTVZone.
              <br />
              4.2. IPTVZone reserves the right to remove or restrict access to
              content that violates these Terms of Use.
            </p>
            <h3 className="mb-3 mt-4">5. Payments and Subscriptions</h3>
            <p>
              5.1. Users agree to pay the subscription fees as per the chosen
              plan.
              <br />
              5.2. Subscription fees are non-refundable.
              <br />
              5.3. Upon successful payment, users will receive their IPTV
              subscription details via email within 48 hours. If there are any
              delays or issues, users are encouraged to contact our customer
              support.
            </p>
            <h3 className="text-4">6. Termination</h3>
            <p>
              IPTVZone reserves the right to terminate or suspend user accounts
              without prior notice if they violate these Terms of Use.
            </p>
            <h3 className="text-4">7. Privacy Policy</h3>
            <p>
              Please refer to our Privacy Policy for information on how we
              collect, use, and disclose user information.
            </p>
            <h3 className="text-4">8. Changes to Terms of Use</h3>
            <p>
              IPTVZone reserves the right to modify these Terms of Use at any
              time. Users will be notified of significant changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
