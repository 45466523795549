import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PriceProvider } from "./config/priceContext";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sass/stylesheet.scss";
import "./sass/color-blue.scss";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <PriceProvider>
      <Helmet>
        <title>IPTVZone</title>
        <meta name="description" content="Cheapest IPTV Subscription ever" />
        {/* Add more meta tags as needed for your SEO requirements */}
        <link rel="canonical" href="https://www.yourwebsite.com/" />
        {/* Open Graph meta tags for better social sharing */}
        <meta property="og:title" content="IPTVZone" />
        <meta property="og:description" content="IPTV suubscriptions for cheap" />
        <meta property="og:url" content="https://www.yourwebsite.com/" />
        {/* Add more Open Graph meta tags as needed */}
      </Helmet>
      <App />
    </PriceProvider>
  </HelmetProvider>
);

reportWebVitals();
