import React from "react";
import { Helmet } from "react-helmet-async";
import AboutUs from "../components/About";
import AppScreenshots from "../components/AppScreenshots";
import AppScreenshots2 from "../components/AppScreenshots2";
import Contact from "../components/Contact";
import Counter from "../components/Counter";
import FAQs from "../components/FAQs";
import Features from "../components/Features";
import HowItWorks from "../components/HowItWorks";
import Pricing from "../components/Pricing";
import Subscribe from "../components/Subscribe";
import Testimonials from "../components/Testimonials";
import Intro1 from "../components/themes/Intro/Intro1";
import Intro2 from "../components/themes/Intro/Intro2";
import {
  appliedConfig,
  HeaderConfig,
  IntroThemeConfig,
} from "../config/commonConfig";
import { usePrice } from "../config/priceContext";

export const Main = () => {
  const { setPlanPrice } = usePrice();
  const appliedHeader = appliedConfig.appliedHeader;
  const appliedIntro = appliedConfig.appliedIntro;

  const getIntro = () => {
    if (appliedIntro === IntroThemeConfig.Intro1) {
      return <Intro1></Intro1>;
    } else if (appliedIntro === IntroThemeConfig.Intro2) {
      return <Intro2></Intro2>;
    } else {
      return <Intro1></Intro1>; // Make sure to return the default Intro
    }
  };

  return (
    <div id="content" role="main">
      <Helmet>
        <meta name="description" content="IPTVZone" />
      </Helmet>

      {getIntro()}

      <AboutUs></AboutUs>
      <AppScreenshots2></AppScreenshots2>
      <Counter></Counter>
      <Pricing setPlanPrice={setPlanPrice}></Pricing>
      <Features></Features>
      <AppScreenshots></AppScreenshots>
      <HowItWorks></HowItWorks>
      <Testimonials></Testimonials>
      {/* <GetTheApp></GetTheApp> */}
      <FAQs></FAQs>
      {/* <Subscribe></Subscribe> */}
      <Contact></Contact>
    </div>
  );
};
