import React from "react";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

const Testimonials = () => {
  const reviews = [
    {
      name: "Yarin",
      position: "User from Israel",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "“I would personally want to thank you, the service is great. I have all the Israeli channels and it works great! Best price ever.”",
    },
    {
      name: "Samuel",
      position: "User from Spain",
      src: "images/testimonial/client-sm-2.jpg",
      desc: "“The content is good, quality does too. good service!.”",
    },
    {
      name: "Thomas",
      position: "User from Uk",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "“I had an few problems but they helped me fix it, now it works good! thank you!”",
    },
    {
      name: "Oliver",
      position: "User from UK",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "“Using IPTV services for quite a while, these prices are unmatched!”",
    },
    {
      name: "Serge",
      position: "User from UK",
      src: "images/testimonial/client-sm-4.jpg",
      desc: "“Great IPTV, no buffering at all, great choice of Channels and VOD selection in many languages, worked great with any IPTV player I tried and the customer service so far has been amazing.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
    <Helmet>
    <meta
      name="og:title"
      content="Testimonials- IPTVZone"
    />
    <meta
      name="description"
      content="Read what our users say about our IPTV services. Real testimonials from satisfied customers."
    />
  </Helmet>
    <section
      id="testimonial"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">What Our Users Say’s</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <div className="lead text-center mt-4 mb-4">
        Want to cheer us on? {" "}
          <a
            href="#contact"
            className="smooth-scroll link-offset-2 link-underline link-underline-opacity-0-hover"
          >
            Write us some positive feedback.
          </a>
        </div>
        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((review, index) => (
              <div
                className="item testimonial border rounded text-center p-4"
                key={index}
              >
                <span className="text-9 text-body-tertiary opacity-2">
                  <i className="fa fa-quote-left" />
                </span>
                <p>{review.desc}</p>
                {/* <img
                  className="img-fluid d-inline-block w-auto rounded-circle shadow-sm border"
                  src={review.src}
                  alt=""
                /> */}
                <strong className="d-block fw-500">{review.name}</strong>{" "}
                <span className="text-body-tertiary text-2">
                  {review.position}
                </span>{" "}
              </div>
            ))}
        </Slider>
      </div>
    </section>
    </>
  );
};

export default Testimonials;
