import React from "react";
import { Helmet } from "react-helmet-async";
import {
  HeaderConfig,
  IntroBgConfig,
  IntroContentConfig,
  appleAppStoreLink,
  appliedConfig,
  googlePlayStoreLink,
} from "../../../config/commonConfig";
import { Parallax } from "react-parallax";

function Intro2() {
  const applyOpacityAndMask = () => {
    if (appliedConfig.appliedIntroBg === IntroBgConfig.Default) {
      return "opacity-1 bg-dark";
    } else if (appliedConfig.appliedIntroBg === IntroBgConfig.Image) {
      return "opacity-5 bg-dark";
    } else {
      return "opacity-9 bg-dark";
    }
  };

  return (
    <section id="home">
      <Helmet>
        <title>IPTVZone - Beyond Channels, Beyond Limits</title>
        <meta
          name="description"
          content="Welcome to IPTVZone, where streaming brilliance meets infinite entertainment. Redefine your TV experience with seamless streaming, unmatched quality, and a world beyond limits. Experience the future of TV today."
        />
        <meta property="og:title" content="IPTVZone - Beyond Channels, Beyond Limits" />
        <meta
          property="og:description"
          content="Welcome to IPTVZone, where streaming brilliance meets infinite entertainment. Redefine your TV experience with seamless streaming, unmatched quality, and a world beyond limits. Experience the future of TV today."
        />
        <meta property="og:image" content="URL_TO_YOUR_LOGO_IMAGE" />
        <meta property="og:url" content="URL_TO_YOUR_WEBSITE" />
      </Helmet>
      <div className="hero-wrap">
        <div className={"hero-mask  " + applyOpacityAndMask()} />
        {appliedConfig.appliedIntroBg === IntroBgConfig.Video && (
          <div className="player hero-bg ">
            <video
              autoPlay
              muted
              loop
              playsinline="true"
              style={{
                width: "100%",
                height: "100%",
                minHeight: "100%",
                objectFit: "cover",
              }}
            ></video>
          </div>
        )}
        {appliedConfig.appliedIntroBg === IntroBgConfig.Image && (
          <div className="intro-parallax-container">
              <Parallax
                className="hero-bg"
                bgImage="images/intro-bg.jpg"
                bgImageAlt="Intro"
                strength={200}
                renderLayer={(percentage) => (
                  <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(images/intro-bg.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    transform: `scale(${1 + percentage * 0.1})`, // Adjust the zoom-out effect
                  }}
                  />
                )}
              />
            </div>
        )}

        <div
          className={
            "hero-content d-flex " +
            (appliedConfig.appliedHeader === HeaderConfig.ClassicHeader
              ? "fullscreen-with-header "
              : "fullscreen pt-5 ") +
            (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
            appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
              ? "px-lg-5 "
              : " ")
          }
        >
          <div className="container my-auto">
            <div className="row my-5 my-lg-0">
              <div className="col-lg-6 align-self-center text-center text-lg-start order-1 order-lg-0">
                <h2
                  className={
                    "text-16 fw-600 mb-3 " +
                    (appliedConfig.appliedIntroBg === IntroBgConfig.Video ||
                    appliedConfig.appliedIntroBg === IntroBgConfig.Image
                      ? "text-white "
                      : " ")
                  }
                >
                  Beyond Channels,{" "}
                  <span
                    className={
                      appliedConfig.appliedIntroBg === IntroBgConfig.Default
                        ? "text-primary"
                        : ""
                    }
                  >
                    Beyond Limits.
                  </span>{" "}
                </h2>
                <p
                  className={
                    "lead mb-4 " +
                    (appliedConfig.appliedIntroBg === IntroBgConfig.Video ||
                    appliedConfig.appliedIntroBg === IntroBgConfig.Image
                      ? "text-white "
                      : " ")
                  }
                >
                  Welcome to IPTVZone, where streaming brilliance meets infinite
                  entertainment. Redefine your TV experience with seamless
                  streaming, unmatched quality, and a world beyond limits.
                  Experience the future of TV today.
                </p>
               
              </div>
              {/* image content start */}
              {appliedConfig.appliedIntroContent ===
                IntroContentConfig.Image && (
                <div className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 mb-4 mb-lg-0">
                  <img
                    className="img-fluid phone-img-multi-disney"
                    src="images/app-showcase123.png"
                    alt="IPTVZone App Showcase"
                  />
                </div>
              )}
              {/* image content end */}

              {/* youtube content start */}
              {appliedConfig.appliedIntroContent ===
                IntroContentConfig.Video && (
                <div className="col-lg-6 align-self-center order-0 order-lg-1 mb-5 mb-lg-0">
                  <div className="player shadow-lg rounded-lg ratio ratio-16x9">
                    <iframe
                      src="https://www.youtube.com/embed/5W29ZASPo3I?autoplay=1&mute=1"
                      title="App explainer video | Now Health International | 2016"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              )}
              {/* youtube content end */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro2;
